<template>
    <b-overlay :show="loading" opacity="1">
        <b-card>
            <b-modal id="change-request-detail-edit" size="xl" title="İrsaliyeye Bağlı Değişiklik Talepleri" hide-footer no-close-on-esc no-close-on-backdrop>
                <change-request-edit-view @approve-change-request="approveChangeRequest" v-model="ChangeRequestDetail"></change-request-edit-view>
            </b-modal>
            <div class="row">
                <div class="col-md-12 mb-1 ">
                    <v-stats-box text="Durum" variant="success" icon="info" :value="IrsaliyeGroup.StatusStr" ></v-stats-box>
                </div>
                <div class="col-md-6 mb-1 ">
                    <v-stats-box text="Referans No" variant="warning" icon="info" :value="IrsaliyeGroup.ReferenceNo" ></v-stats-box>
                </div>
                <div class="col-md-6 mb-1">
                    <v-stats-box text="Taşıyıcı Cari Adı" variant="success" icon="handshake" :value="IrsaliyeGroup.CarrierCariName" ></v-stats-box>
                </div>
                
                <div class="col-md-6 mb-1 ">
                    <v-stats-box text="Tarih Aralığı" variant="danger" icon="event" :value="($safe(IrsaliyeGroup,'StartDate') || '') + ' - ' + ($safe(IrsaliyeGroup,'EndDate') || '')" ></v-stats-box>
                </div>
                <div class="col-md-6 mb-1 ">
                    <v-stats-box text="Oluşturulma Tarihi" variant="primary" icon="event" :value="IrsaliyeGroup.CreateTime" ></v-stats-box>
                </div>
                
            </div>
            <hr>
            <div v-if="IrsaliyeGroupDetailStats.length>0 && (IrsaliyeGroup.Status == 9 || IrsaliyeGroup.Status ==10 || IrsaliyeGroup.Status == 11 )" class="row mb-2">
                <h2>Özet Fiyat Bilgisi</h2>
                <table class="table table-sm align-middle">
                    <thead>
                        <tr>
                            <th>Varış Noktası</th>
                            <th>Taşıma Durumu</th>
                            <th>Malzeme</th>
                            <th>Araç Tipi</th>
                            <th class="text-right">Toplam Tonaj (TON)</th>
                            <th class="text-right">Birim Fiyat (TL/TON)</th>
                            <th class="text-right">Toplam Tutar (TL)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(data, index) in IrsaliyeGroupDetailStats">
                            <tr :key="index">
                                    <td class="text-nowrap">{{ data.CustomerName }}</td>
                                    <td class="text-nowrap">{{ data.VehicleTransportStatus }}</td>
                                    <td class="text-nowrap">{{ data.MaterialName }}</td>
                                    <td class="text-nowrap">{{ data.VehicleType }}</td>
                                    <td class="text-nowrap text-right">{{ data.TotalTonnage }}</td>
                                    <td class="text-nowrap text-right">{{ data.UnitPrice }}</td>
                                    <td class="text-nowrap text-right">{{ data.TotalPrice }}</td>
                                </tr>
                        </template>
                        <tr>
                            <td colspan="4"></td>
                            <td class="font-weight-bolder text-secondary text-right">{{sumTotalTonnage(IrsaliyeGroupDetailStats)}}</td>
                            <td></td>
                            <td class="font-weight-bolder text-secondary text-right">{{sumTotalPrice(IrsaliyeGroupDetailStats)}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row">
                <h2>Seferler</h2>
                <div class="row mt-2 table-responsive" >
                    <table class="table table-sm align-middle">
                        <thead>
                            <tr>
                                <th>Sıra</th>
                                <th v-if="IrsaliyeGroup.Status == 8 ||IrsaliyeGroup.Status == 9 || IrsaliyeGroup.Status == 6" class="text-nowrap">İşlemler</th>
                                <th v-if="IrsaliyeGroup.Status !=  5 && IrsaliyeGroup.Status != 1" class="text-nowrap">Yanıtlama Durumu</th>
                                <th class="text-nowrap">İrsaliye No</th>
                                <th class="text-nowrap">Başlangıç Bölge</th>
                                <th class="text-nowrap">Taşınan Firma Adı</th>
                                <th class="text-nowrap">Bitiş Bölge</th>
                                <th class="text-nowrap">Araç Tipi</th>
                                <th class="text-nowrap">Plaka</th>
                                <th class="text-nowrap">Ürün Tipi</th>
                                <th class="text-nowrap text-right">Ton</th>
                                <th v-if="IrsaliyeGroup.Status == 9 || IrsaliyeGroup.Status == 6 || IrsaliyeGroup.Status == 10" class="text-nowrap text-right">Fiyat</th>
                                <th class="text-nowrap">Tarih</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="(data, index) in IrsaliyeGroup.IrsaliyeSefers">
                                <tr :key="index">
                                    <td class="text-center font-weight-bolder p-0">{{ index + 1 }}</td>
                                    <td v-if="IrsaliyeGroup.Status ==  8 ||IrsaliyeGroup.Status == 9 || IrsaliyeGroup.Status == 6" class="text-nowrap">
                                        
                                        <b-dropdown v-if="data.IsChangeRequestHas" dropright size="sm" variant="light" no-caret>
                                            <template #button-content>
                                                <feather-icon class="mr-50" icon="MoreHorizontalIcon"></feather-icon>
                                                İşlemler
                                            </template>    
                                            <b-dropdown-item  variant="primary"  v-on:click="getChangeRequestDetail(data.Id)">
                                                <feather-icon class="mr-50" icon="InfoIcon" size="16" ></feather-icon>
                                                Değişiklik Talepleri
                                            </b-dropdown-item> 
                                        </b-dropdown>
                                        
                                    </td>
                                    <td v-if="IrsaliyeGroup.Status !=  5 &&  IrsaliyeGroup.Status !=  1 && data.IsChangeRequestHas" :style="data.AnsweredCountCheck ? 'color:green;':'color:red;'" class="text-nowrap">{{ data.AnsweredCount }}</td>
                                    <td v-if="IrsaliyeGroup.Status !=  5 && IrsaliyeGroup.Status !=  1 && !data.IsChangeRequestHas"  class="text-nowrap"></td>
                                    <td class="text-nowrap">{{ data.IrsaliyeNo }}</td>
                                    <td class="text-nowrap">{{ data.BaslangicRegion }}</td>
                                    <td class="text-nowrap">{{ data.BitisFirmaAdi }}</td>
                                    <td class="text-nowrap">{{ data.BitisRegion }}</td>
                                    <td class="text-nowrap">{{ data.VehicleTypeName }}</td>
                                    <td class="text-nowrap">{{ data.TasiyiciAracPlaka }}</td>
                                    <td class="text-nowrap">{{ data.TasinanUrunAdi }}</td>
                                    <td class="text-nowrap text-right">{{ data.TasinanUrunMiktariStr }}</td>
                                    <td v-if="IrsaliyeGroup.Status == 9 ||IrsaliyeGroup.Status == 6||IrsaliyeGroup.Status == 10" class="text-nowrap text-right"><b-badge class="primary">{{ data.PriceStr +' ₺'}} </b-badge></td>
                                    <td class="text-nowrap">{{ data.Gun }}</td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row justify-content-end">
                <div  class="col-auto mb-1 mb-md-0">
                    <v-executer :loading="sendToCustomerLoading" v-if="IrsaliyeGroup.Status == 5 || IrsaliyeGroup.Status == 1" :action="sendToCustomer">Tedarikçiye Onay İçin Gönder</v-executer>
                    <v-executer class="mr-1" :loading="calculatePriceLoading" v-if="IrsaliyeGroup.Status == 8 || IrsaliyeGroup.Status == 9" :action="calculatePrice">Fiyatları Oluştur</v-executer>
                    <v-executer :loading="sendCompletedIrsaliyeGroupToCustomerLoading" v-if="IrsaliyeGroup.Status == 9 || IrsaliyeGroup.Status == 10" :action="sendCompletedIrsaliyeGroupToCustomer" >Tedarikçiye Fiyat Bilgisi Gönder</v-executer>
                </div>
            </div>
        </b-card>
    </b-overlay>
</template>

<script>
import { BBadge, BCard,BFormCheckbox,BDropdown,BDropdownItem,BOverlay  } from 'bootstrap-vue'
import { IrsaliyeOperationsApi } from '@/api/irsaliye'
import VStatsBox from '@/components/VStatsBox.vue'
import CounterTonnageEdit from './components/CounterTonnageEdit.vue'
import Auth from '@/utilities/auth'
import ChangeRequestEditView from './components/ChangeRequestEditView.vue'
import { AgreementApi } from '@/api/agreement'

export default {
    components:{
        ChangeRequestEditView,
        CounterTonnageEdit,
        BCard,
        BBadge,
        VStatsBox,
        BFormCheckbox,
        BDropdown,
        BDropdownItem,
        BOverlay
    },
    data(){
        return{
            IrsaliyeGroupDetailStats:[],
            EditTonnage:{
                Id:null,
                Tonnage:null
            },
            loading:false,
            counterTonnageLoading:false,
            IrsaliyeGroup:{
                Id:null,
                CarrierCariName:null,
                CarrierCariCode:null,
                ReferenceNo:null,
                CreateTime:null,
                StartDate:null,
                EndDate:null,
                IrsaliyeNo:null,
                IrsaliyeSefers:[],
                Status:null,
                StatusStr:null,
                IsCounterTonnage:null
            },
            ChangeRequestDetail:{
                IrsaliyeNo:null,
                CounterTonnageRequestId:null,
                CounterTonnage:null,
                FixedFirmNameId:null,
                FixedFirmName:null,
                FixedFirmCariCode:null,
                FixedFirmCariName:null,
                ExtraRouteId:null,
                ExtraRoute:{
                    BitisFirmaAdi:null,
                    CariName:null,
                    IrsaliyeNo:null,
                    Tonnage:null,
                    VehicleTransportStatus:null,
                    RegionId:null,
                    TasinanUrunAdi:null,
                    TasiyiciAracPlaka:null,
                    TasiyiciAracDorsePlaka:null,
                    Gun:null,
                    StockCode:null,
                    StockName:null,
                    CariCode:null
                },
                CounterTonnageStatus:null,
                CounterTonnageStatusValue:null,
                FixedFirmNameStatus:null,
                FixedFirmNameStatusValue:null,
                ExtraRouteStatus:null,
                ExtraRouteStatusValue:null
            },
            changeRequestModel:{
                ChangeRequestId:null,
                Status:null,
                CariCode:null
            },
            calculatePriceLoading:false,
            sendToCustomerLoading:false,
            sendCompletedIrsaliyeGroupToCustomerLoading:false
        }
    },
    mounted(){
        this.getDetail()
    },
    methods:{
        clearChangeRequestModel(){
            this.changeRequestModel.ChangeRequestId = null
            this.changeRequestModel.Status = null
            this.changeRequestModel.CariCode = null
        },
        isInRole(roles){
            let x = Auth.hasRole([roles])
            return x
        },
        getChangeRequestDetail(data){
            IrsaliyeOperationsApi.GetChageRequestDetail(data).then(res=> {
                this.ChangeRequestDetail.IrsaliyeNo = res.data.Data.IrsaliyeNo
                this.ChangeRequestDetail.CounterTonnageRequestId = res.data.Data.CounterTonnageRequestId
                this.ChangeRequestDetail.CounterTonnage = res.data.Data.CounterTonnage
                this.ChangeRequestDetail.FixedFirmNameId = res.data.Data.FixedFirmNameId
                this.ChangeRequestDetail.FixedFirmName = res.data.Data.FixedFirmName
                this.ChangeRequestDetail.FixedFirmCariCode = res.data.Data.FixedFirmCariCode
                this.ChangeRequestDetail.FixedFirmCariName = res.data.Data.FixedFirmCariName
                this.ChangeRequestDetail.ExtraRouteId = res.data.Data.ExtraRouteId
                this.ChangeRequestDetail.CounterTonnageStatus = res.data.Data.CounterTonnageStatus
                this.ChangeRequestDetail.CounterTonnageStatusValue = res.data.Data.CounterTonnageStatusValue
                this.ChangeRequestDetail.FixedFirmNameStatus = res.data.Data.FixedFirmNameStatus
                this.ChangeRequestDetail.FixedFirmNameStatusValue = res.data.Data.FixedFirmNameStatusValue
                this.ChangeRequestDetail.ExtraRouteStatus = res.data.Data.ExtraRouteStatus
                this.ChangeRequestDetail.ExtraRouteStatusValue = res.data.Data.ExtraRouteStatusValue
                this.ChangeRequestDetail.ExtraRoute.BitisFirmaAdi = res.data.Data.ExtraRoute?.BitisFirmaAdi
                this.ChangeRequestDetail.ExtraRoute.CariName = res.data.Data.ExtraRoute?.CariName
                this.ChangeRequestDetail.ExtraRoute.IrsaliyeNo = res.data.Data.ExtraRoute?.IrsaliyeNo
                this.ChangeRequestDetail.ExtraRoute.VehicleTransportStatus = res.data.Data.ExtraRoute?.VehicleTransportStatus
                this.ChangeRequestDetail.ExtraRoute.Tonnage = res.data.Data.ExtraRoute?.Tonnage
                this.ChangeRequestDetail.ExtraRoute.Gun = res.data.Data.ExtraRoute?.Gun
                this.ChangeRequestDetail.ExtraRoute.RegionName = res.data.Data.ExtraRoute?.RegionName
                this.ChangeRequestDetail.ExtraRoute.StockCode = res.data.Data.ExtraRoute?.StockCode
                this.ChangeRequestDetail.ExtraRoute.CariCode = res.data.Data.ExtraRoute?.CariCode
                this.ChangeRequestDetail.ExtraRoute.StockName = res.data.Data.ExtraRoute?.StockName
                this.ChangeRequestDetail.ExtraRoute.TasiyiciAracDorsePlaka = res.data.Data.ExtraRoute?.TasiyiciAracDorsePlaka
                this.ChangeRequestDetail.ExtraRoute.TasiyiciAracPlaka = res.data.Data.ExtraRoute?.TasiyiciAracPlaka
                this.$showModal('change-request-detail-edit')
            })
            
        },
        getDetail(){
            this.loading = true
            const id = this.$route.params.id
            IrsaliyeOperationsApi.Detail(id).then(res => {
                if(!res.data.Success)
                    this.$result(res)
                else{
                    this.IrsaliyeGroup.Id=res.data.Data.Id
                    this.IrsaliyeGroup.IrsaliyeNo = res.data.Data.IrsaliyeNo
                    this.IrsaliyeGroup.CarrierCariName = res.data.Data.CarrierCariName
                    this.IrsaliyeGroup.CarrierCariCode = res.data.Data.CarrierCariCode
                    this.IrsaliyeGroup.ReferenceNo = res.data.Data.ReferenceNo
                    this.IrsaliyeGroup.CreateTime = res.data.Data.CreateTime
                    this.IrsaliyeGroup.StartDate = res.data.Data.StartDate
                    this.IrsaliyeGroup.EndDate = res.data.Data.EndDate
                    this.IrsaliyeGroup.IrsaliyeSefers = res.data.Data.IrsaliyeSefers
                    this.IrsaliyeGroup.Status = res.data.Data.Status
                    this.IrsaliyeGroup.StatusStr = res.data.Data.StatusStr
                    this.IrsaliyeGroup.IsCounterTonnage = res.data.Data.IsCounterTonnage

                    IrsaliyeOperationsApi.DetailStats(id).then(res => {
                        if(!res.data.Success)
                            this.$result(res)
                        else{
                            this.IrsaliyeGroupDetailStats = res.data.Data
                        }
                    }).catch(err => {
                        console.error(err);
                    })

                    this.loading = false
                }
            }).catch(err => {
                console.error(err);
            })

            

        },
        sendToCustomer(){
            this.sendToCustomerLoading=true
            this.$confirm('İlgili İrsaliye Grubu Tedarikçiye Gönderilecektir. Onaylıyor musunuz?',`Bu işlem, ${this.IrsaliyeGroup.ReferenceNo} adlı irsaliye grubunu tedarikçiye onay için gönderecektir.`).then(result=>{
                if(result){
                    IrsaliyeOperationsApi.SendToCustomer(this.IrsaliyeGroup.Id).then(res => {
                        this.$result(res)
                        this.sendToCustomerLoading=false
                        if(res.data.Success){
                            this.getDetail()
                        }
                    })
                }else{
                    this.sendToCustomerLoading=false
                }
            })
        },
        sendCompletedIrsaliyeGroupToCustomer(){
            this.sendCompletedIrsaliyeGroupToCustomerLoading=true
            this.$confirm('Fiyat Çalışılmış İlgili İrsaliye Grubu Tedarikçiye Gönderilecektir. Onaylıyor musunuz?',`Bu işlem, ${this.IrsaliyeGroup.ReferenceNo} adlı irsaliye grubuna ait çalışılmış fiyatı tedarikçiye gönderecektir. Bu işlemin geri dönüşü yoktur. Onaylıyor musunuz?`).then(result=>{
                if(result){
                    IrsaliyeOperationsApi.SendCompletedIrsaliyeGroupToCustomer(this.IrsaliyeGroup.Id).then(res => {
                        this.$result(res)
                        if(res.data.Success){
                            this.getDetail()
                        }
                        this.sendCompletedIrsaliyeGroupToCustomerLoading=false
                    }).catch(err=>{
                        this.sendCompletedIrsaliyeGroupToCustomerLoading=false
                    })
                }else{
                    this.sendCompletedIrsaliyeGroupToCustomerLoading = false
                }
            })
        },
        calculatePrice(){
            this.calculatePriceLoading=true;
            this.$confirm('İlgili İrsaliye Grubu İçin Fiyat Çalıştırılacaktır. Bu işlemin geri dönüşü yoktur. Onaylıyor musunuz?',`Bu işlem, ${this.IrsaliyeGroup.ReferenceNo} adlı irsaliye grubuna ait fiyatları hazırlayacaktır.`).then(result=>{
                if(result){
                    IrsaliyeOperationsApi.CalculatePrice(this.IrsaliyeGroup.Id).then(res => {
                        this.$result(res)
                        this.calculatePriceLoading=false
                        if(res.data.Success){
                            this.getDetail()
                        }
                    }).catch(err => {
                        this.calculatePriceLoading=false
                    })
                }
                else{
                    this.calculatePriceLoading=false
                }
            })
            
        },
        sumTotalPrice(items){
            let price = 0;
            price = (items || []).reduce((sum,item) => sum += item.TotalPriceValue,0)
            return new Intl.NumberFormat('tr-TR', {
                currency: 'TRY',
                minimumFractionDigits: 2,
            }).format(price)
        },
        sumTotalTonnage(items){
            return  (items || []).reduce((sum,item) => sum += item.TotalTonnageValue,0)
        },
        approveChangeRequest(id, status, type){
            this.clearChangeRequestModel();
            let changeRequestId = id
            this.changeRequestModel.ChangeRequestId = id
            this.changeRequestModel.Status = status
            if(type=='extra-route'){
                this.changeRequestModel.CariCode= this.ChangeRequestDetail.ExtraRoute.CariCode
            }
            if(type == 'fixed-firm'){
                this.changeRequestModel.CariCode= this.ChangeRequestDetail.FixedFirmCariCode
            }
            IrsaliyeOperationsApi.ChangeStatusIrsaliyeGroupRouteChangeRequest(this.changeRequestModel)
                .then(res => {
                    if(res.data.Success){
                        this.$hideModal('change-request-detail-edit')
                        // this.getChangeRequestDetail(changeRequestId);
                        this.getDetail()
                    }
                    this.$result(res)
                }).catch(err=>{
                    console.log(err);
                })
                this.loading = false
        },
        
    }
}
</script>

<style>

</style>