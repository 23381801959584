<template>
    <div class="row col-md-12">
        <div class="row col-md-12 mb-1">
            <h4>{{ value.IrsaliyeNo }} irsaliyesine ait değişim talepleri:</h4>
        </div>
        <div class="col-md-12 col-sm-12 col-lg-6 table-responsive" v-if="value.CounterTonnage != null">
            <h5>Tonaj Bilgisi</h5>
            <table  class="table table-sm table-bordered align-middle">
                <thead>
                    <tr>
                        <td class="w-5 text-nowrap"><strong>Durum</strong></td>
                        <td class="w-70 text-nowrap"><strong>Tonaj Bilgisi</strong></td>
                        <td v-if="value.CounterTonnageStatusValue == 0" class="w-25 text-nowrap"><strong>İşlemler</strong></td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div class="text-center">
                                <span v-if="value.CounterTonnageStatusValue == 0" class="material-icons-outlined text-warning">watch_later</span>
                                <span v-if="value.CounterTonnageStatusValue == 1" class="material-icons-outlined text-success">check_circle</span>
                                <span v-if="value.CounterTonnageStatusValue == 2" class="material-icons-outlined text-danger">do_not_disturb_on</span>
                            </div>
                        </td>
                        <td>{{ value.CounterTonnage }}</td>
                        <td v-if="value.CounterTonnageStatusValue == 0">
                                <div class="row justify-content-center col-12 mb-1 mt-1">
                                    <button class="btn btn-success" v-on:click="$emit('approve-change-request',value.CounterTonnageRequestId,1,'counter-tonnage')">Onayla</button>
                                </div>
                                <div class="row justify-content-center col-12 mb-1">
                                    <button class="btn btn-danger" v-on:click="$emit('approve-change-request',value.CounterTonnageRequestId,2,'counter-tonnage')">Reddet</button>
                                </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-12 col-sm-12 col-lg-6 table-responsive"  v-if="value.FixedFirmNameId != null">
            <h5>Düzenlenmiş Firma</h5>
            <table  class="table table-sm table-bordered align-middle">
                <thead>
                    <tr>
                        <td class="text-nowrap w-5">Durum</td>
                        <td class="text-nowrap w-20"><strong>Düzenlenmiş Firma Adı</strong></td>
                        <td v-if="value.FixedFirmNameStatusValue == 0" class="text-nowrap w-50"><strong>Firma Seçimi</strong></td>
                        <td v-if="value.FixedFirmNameStatusValue == 0" class="text-nowrap w-25"><strong>İşlemler</strong></td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div class="text-center">
                                <span v-if="value.FixedFirmNameStatusValue == 0" class="material-icons-outlined text-warning">watch_later</span>
                                <span v-if="value.FixedFirmNameStatusValue == 1" class="material-icons-outlined text-success">check_circle</span>
                                <span v-if="value.FixedFirmNameStatusValue == 2" class="material-icons-outlined text-danger">do_not_disturb_on</span>
                            </div>
                        </td>
                        <td v-if="value.FixedFirmNameStatusValue == 0 || value.FixedFirmNameStatusValue == 2">{{ value.FixedFirmName }}</td>
                        <td v-if="value.FixedFirmNameStatusValue == 1">{{ value.FixedFirmCariName }}</td>
                        <td v-if="value.FixedFirmNameStatusValue == 0">
                            <label class="form-label">Düzenlenecek Tedarikçi Firma Adı</label>
                            <v-select-list append-to-body :loading="fixedFirmCariLoading" :options="fixedFirmCariList"  v-on:search="getFixedFirmCariList" v-model="fixedFirmCari" placeholder="Cari" label="CARI_ISIM"></v-select-list>
                        </td>
                        <td v-if="value.FixedFirmNameStatusValue == 0">
                                <div class="row justify-content-center col-12 mb-1 mt-1">
                                    <button class="btn btn-success" v-on:click="$emit('approve-change-request',value.FixedFirmNameId,1,'fixed-firm')">Onayla</button>
                                </div>
                                <div class="row justify-content-center col-12 mb-1">
                                    <button class="btn btn-danger" v-on:click="$emit('approve-change-request',value.FixedFirmNameId,2,'fixed-firm')">Reddet</button>
                                </div>
                        </td>
                        
                    </tr>
                </tbody>
            </table>
        </div>
        
        <div class="col-md-12 col-sm-12 table-responsive" v-if="value.ExtraRoute.BitisFirmaAdi != null ">
            <h5 >Dönüş İrsaliye</h5>           
            <table class="responsive table table-sm table-bordered align-middle" v-if="value.ExtraRoute.BitisFirmaAdi != null">
                <thead>
                    <tr>
                        <td class="text-nowrap w-5"><strong>Durum</strong></td>
                        <td class="text-nowrap w-5"><strong>İrsaliye No</strong></td>
                        <td class="text-nowrap w-10"><strong>Tedarikçi Firma Adı</strong></td>
                        <td class="text-nowrap w-10"><strong>Tonaj</strong></td>
                        <td class="text-nowrap w-10"><strong>Taşıma tipi</strong></td>
                        <td class="text-nowrap w-10"><strong>Bölge</strong></td>
                        <td class="text-nowrap w-5"><strong>Stok</strong></td>
                        <td class="text-nowrap w-5"><strong>Taşıyıcı Araç Plakası</strong></td>
                        <td class="text-nowrap w-5"><strong>Taşıyıcı Araç Dorse Plaka</strong></td>
                        <td class="text-nowrap w-5"><strong>Tarih</strong></td>
                        <td v-if="value.ExtraRouteStatusValue == 0" class="text-nowrap w-20"><strong>Firma Seçimi</strong></td>
                        <td v-if="value.ExtraRouteStatusValue == 0" class="text-nowrap w-10"><strong>İşlemler</strong></td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="w-5">
                            <div class="text-center">
                                <span v-if="value.ExtraRouteStatusValue == 0" class="material-icons-outlined text-warning">watch_later</span>
                                <span v-if="value.ExtraRouteStatusValue == 1" class="material-icons-outlined text-success">check_circle</span>
                                <span v-if="value.ExtraRouteStatusValue == 2" class="material-icons-outlined text-danger">do_not_disturb_on</span>
                            </div>
                        </td>
                        <td>{{ value.ExtraRoute.IrsaliyeNo }}</td>
                        <td v-if="value.ExtraRouteStatusValue == 0 || value.ExtraRouteStatusValue == 2" >{{ value.ExtraRoute.BitisFirmaAdi }}</td>
                        <td v-if="value.ExtraRouteStatusValue ==1">{{ value.ExtraRoute.CariName }}</td>
                        <td >{{ value.ExtraRoute.Tonnage }}</td>
                        <td >{{ value.ExtraRoute.VehicleTransportStatus }}</td>
                        <td >{{ value.ExtraRoute.RegionName }}</td>
                        <td >{{ value.ExtraRoute.StockName }}</td>
                        <td >{{ value.ExtraRoute.TasiyiciAracPlaka }}</td>
                        <td >{{ value.ExtraRoute.TasiyiciAracDorsePlaka }}</td>
                        <td >{{ value.ExtraRoute.Gun }}</td>
                        <td v-if="value.ExtraRouteStatusValue == 0" >
                            <div v-if="value.ExtraRoute.BitisFirmaAdi != null">
                                <label class="form-label">Düzenlenecek Tedarikçi Firma Adı</label>
                                <v-select-list append-to-body :loading="cariLoading" :options="cariList"  v-on:search="getCariList" v-model="cari" placeholder="Cari" label="CARI_ISIM"></v-select-list>
                            </div>
                        </td>
                        <td v-if="value.ExtraRouteStatusValue == 0">
                                <div class="row justify-content-center col-12 mb-1 mt-1">
                                    <button v-if="value.ExtraRouteStatusValue == 0" v-on:click="$emit('approve-change-request',value.ExtraRouteId,1,'extra-route')" class="btn btn-success">Onayla</button>
                                </div>
                                <div class="row justify-content-center col-12 mb-1">
                                    <button v-if="value.ExtraRouteStatusValue == 0" v-on:click="$emit('approve-change-request',value.ExtraRouteId,2,'extra-route')" class="btn btn-danger">Reddet</button>
                                </div>
                        </td>
                        
                    </tr>
                </tbody>
            </table>
            
        </div>
        
    </div>
</template>

<script>
import { NetsisApi } from '@/api/netsis'
export default {
    props:{
        value:Object
    },
    data(){
        return{
            cariLoading:false,
            cariList:[],
            fixedFirmCariLoading:false,
            fixedFirmCariList:[]
        }
    },
    mounted(){
       this.getCariList(this.value.ExtraRoute.CariCode)
       this.getFixedFirmCariList(this.value.ExtraRoute.CariCode)
    },
    methods:{
        getCariList(data){
            if(data){
                this.cariLoading = true
                NetsisApi.CariList(data).then(res => {
                    this.cariList = res.data.Data
                    this.cariLoading = false
                }).catch(err=> {
                    console.error(err)
                    this.cariLoading = false
                })
            }
            
        },
        getFixedFirmCariList(data){
            if(data){
                this.fixedFirmCariLoading = true
                NetsisApi.CariList(data).then(res => {
                    this.fixedFirmCariList = res.data.Data
                    this.fixedFirmCariLoading = false
                }).catch(err=> {
                    console.error(err)
                    this.fixedFirmCariLoading = false
                })
            }
            
        },
    },
    computed:{
        cari:{
            get:function(){
                return this.cariList.find(s=>s.CARI_KOD==this.value.ExtraRoute.CariCode)
            },
            set:function(value){
                this.value.ExtraRoute.CariCode = value?.CARI_KOD || null
            }
        },
        fixedFirmCari:{
            get:function(){
                return this.cariList.find(s=>s.CARI_KOD==this.value.FixedFirmCariCode)
            },
            set:function(value){
                this.value.FixedFirmCariCode = value?.CARI_KOD || null
            }
        }
    }
}
</script>

<style>

</style>